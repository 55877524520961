.h-text-left {
  text-align: left;
}

.h-text-center {
  text-align: center;
}

.h-text-right {
  text-align: right;
}

.h-no-wrap {
  white-space: nowrap;
}

.h-position-relative {
  position: relative;
}

.h-break-lines {
  white-space: pre-line;
  hyphens: auto;
}

.h-text-top {
  vertical-align: top;
}

.h-text-bold {
  font-weight: bold;
}
