@import '../env';

.sss-table {

  width: 100%;

  tr {
    height: 64px;
  }

  th {
    @include wb-text('s', (strong: true));
  }

  td {
    @include wb-text('m');
  }

  th,
  td {
    vertical-align: middle;
  }

  & &__row {
    &--clickable {
      cursor: pointer;

      &:hover {
        border: 1px solid var(--wb-grey-70);
      }
    }
  }

  & &__cell {
    &--clickable {
      cursor: pointer;
    }

    &--break-word {
      word-break: break-word;
    }

    &--text-right {
      text-align: right;
    }

    &--fit-content {
      width: 1%;
    }
  }
}
