/**
 * workbench overrides and extensions
 */

/** extends wb-tag */

.sss-tag--grey-lighter {
  color: var(--wb-grey-70);
  border: 1px solid var(--wb-grey-85);
  background-color: var(--wb-grey-85);
}

.sss-tag--error {
  color: var(--wb-white);
  border: 1px solid var(--wb-red-45);
  background-color: var(--wb-red-45);
}

/** extends wb-button */

.sss-button--grey-lighter {
  color: var(--wb-grey-70);
  background-color: transparent;
  border: 1px solid transparent;
}

/**
 * overrides
 */

/* stylelint-disable */

.wb-grid-row {
  margin: 0;
  flex-flow: nowrap;
}
