@import '../env';

.h-loading-1 {
  height: 1 * $unit;
  background: var(--wb-grey-85);
}

.h-loading-2 {
  height: 2 * $unit;
  background: var(--wb-grey-85);
}

.h-loading-3 {
  height: 3 * $unit;
  background: var(--wb-grey-85);
}

.h-loading-4 {
  height: 4 * $unit;
  background: var(--wb-grey-85);
}
