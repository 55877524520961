@import 'env';
@import 'globals/distances';
@import 'globals/flex';
@import 'globals/tables';
@import 'globals/loading';
@import 'globals/alignments';
@import 'globals/media-queries';
@import 'globals/visibility';
@import 'globals/text';
@import 'globals/layout';

body {
  width: 100vw;
  height: 100vh;
  background-color: var(--wb-white);
}

.full-width {
  width: 100%;
}

.sss-loading-state__header {
  padding-top: 96px;
  background-color: var(--wb-black);
}

.sss-loading-state__navigation {
  height: 38px;
  background-color: var(--wb-grey-20);
}

.sss-inactive-background-color {
  background-color: var(--wb-black);
}

.sss-beige-background-color {
  background-color: var(--wb-grey-95);
}

.sss-black-bar::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 400px;
  background: var(--wb-black);
  content: '';
  z-index: -1;
}

.h-global-scroll-lock {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// stylelint-disable selector-max-type
// stylelint-disable selector-attribute-quotes
// stylelint-disable property-no-vendor-prefix
// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-attribute

input[type=number],
input[ssscurrencyvalidator] {
  text-align: right;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// TODO move to component
.sss-dark-card {
  display: block;
  background-color: var(--wb-grey-40);
  padding: 1.75 * $unit 2 * $unit 0 2 * $unit;
  margin-bottom: 2 * $unit;
}

.sss-dark-card__title {
  @include wb-heading('xs');

  margin: 0 0 1.75 * $unit 0;
  color: var(--wb-white);
}

.allow-overflow {
  overflow: visible;
}

.inline-block {
  display: inline-block;
}

.markdown-paragraph {
  margin: 0;
}

.markdown-paragraph:not(:last-child) {
  margin-bottom: 1rem;
}

.markdown-no-margin .markdown-paragraph {
  /* stylelint-disable declaration-no-important */
  margin-bottom: 0 !important;
  /* stylelint-enable declaration-no-important */
}

.markdown-white-link .markdown-link {
  color: var(--wb-white);
  font-weight: 600;
}
