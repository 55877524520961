.h-flex {
  display: flex;
}

.h-flex-row {
  display: flex;
  flex-direction: row;
}

.h-flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.h-flex-column {
  display: flex;
  flex-direction: column;
}

.h-flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.h-flex-max {
  flex: 1;
}

.h-flex-spread {
  justify-content: space-between;
}

.h-flex-align-flex-end {
  justify-content: flex-end;
}

.h-flex-align-center {
  align-items: center;
}

.h-flex-align-start {
  align-items: start;
}

.h-flex-align-end {
  align-items: flex-end;
}

.h-flex-wrap {
  flex-wrap: wrap;
}

.h-flex-justify-center {
  justify-content: center;
}
