// distances

$unit: 1rem;

// media queries

$mq1MaxWidth: 479px;

$mq2MinWidth: 480px;
$mq2MaxWidth: 767px;

$mq3MinWidth: 768px;
$mq3MaxWidth: 1023px;

$mq4MinWidth: 1024px;
$mq4MaxWidth: 1279px;

$mq5MinWidth: 1280px;
$mq5MaxWidth: 1439px;

$mq6MinWidth: 1440px;

// header related

$header-height-mq1-2: 84px;
$header-height-mq3-4: 136px;
$header-height-mq5-6: 148px;

$shop-outlet-selector-height-mq1-3: 132px;
$shop-outlet-selector-height-mq4-6: 79px;

$headline-max-height: 40px;
