@import '../env';

.h-margin-remove { margin: 0; }
.h-margin-left-remove { margin-left: 0; }
.h-margin-right-remove { margin-right: 0; }
.h-margin-top-remove { margin-top: 0; }
.h-margin-bottom-remove { margin-bottom: 0; }
.h-margin-x-remove {
  margin-left: 0;
  margin-right: 0;
}
.h-margin-y-remove {
  margin-top: 0;
  margin-bottom: 0;
}

.h-margin { margin: $unit; }
.h-margin-left { margin-left: $unit; }
.h-margin-right { margin-right: $unit; }
.h-margin-top { margin-top: $unit; }
.h-margin-bottom { margin-bottom: $unit; }
.h-margin-x {
  margin-left: $unit;
  margin-right: $unit;
}
.h-margin-y {
  margin-top: $unit;
  margin-bottom: $unit;
}

.h-margin-double { margin: 2 * $unit; }
.h-margin-left-double { margin-left: 2 * $unit; }
.h-margin-right-double { margin-right: 2 * $unit; }
.h-margin-top-double { margin-top: 2 * $unit; }
.h-margin-bottom-double { margin-bottom: 2 * $unit; }
.h-margin-x-double {
  margin-left: 2 * $unit;
  margin-right: 2 * $unit;
}
.h-margin-y-double {
  margin-top: 2 * $unit;
  margin-bottom: 2 * $unit;
}

.h-margin-half { margin: 0.5 * $unit; }
.h-margin-left-half { margin-left: 0.5 * $unit; }
.h-margin-right-half { margin-right: 0.5 * $unit; }
.h-margin-top-half { margin-top: 0.5 * $unit; }
.h-margin-bottom-half { margin-bottom: 0.5 * $unit; }
.h-margin-x-half {
  margin-left: 0.5 * $unit;
  margin-right: 0.5 * $unit;
}
.h-margin-y-half {
  margin-top: 0.5 * $unit;
  margin-bottom: 0.5 * $unit;
}

.h-margin-quarter { margin: 0.25 * $unit; }
.h-margin-left-quarter { margin-left: 0.25 * $unit; }
.h-margin-right-quarter { margin-right: 0.25 * $unit; }
.h-margin-top-quarter { margin-top: 0.25 * $unit; }
.h-margin-bottom-quarter { margin-bottom: 0.25 * $unit; }
.h-margin-x-quarter {
  margin-left: 0.25 * $unit;
  margin-right: 0.25 * $unit;
}
.h-margin-y-quarter {
  margin-top: 0.25 * $unit;
  margin-bottom: 0.25 * $unit;
}

.h-padding { padding: $unit; }
.h-padding-left { padding-left: $unit; }
.h-padding-right { padding-right: $unit; }
.h-padding-top { padding-top: $unit; }
.h-padding-bottom { padding-bottom: $unit; }
.h-padding-x {
  padding-left: $unit;
  padding-right: $unit;
}
.h-padding-y {
  padding-top: $unit;
  padding-bottom: $unit;
}

.h-padding-remove { padding: 0 !important; }
.h-padding-left-remove { padding-left: 0 !important; }
.h-padding-right-remove { padding-right: 0 !important; }
.h-padding-top-remove { padding-top: 0 !important; }
.h-padding-bottom-remove { padding-bottom: 0 !important; }
.h-padding-x-remove {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.h-padding-y-remove {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.h-padding-double { padding: 2 * $unit; }
.h-padding-left-double { padding-left: 2 * $unit; }
.h-padding-right-double { padding-right: 2 * $unit; }
.h-padding-top-double { padding-top: 2 * $unit; }
.h-padding-bottom-double { padding-bottom: 2 * $unit; }
.h-padding-x-double {
  padding-left: 2 * $unit;
  padding-right: 2 * $unit;
}
.h-padding-y-double {
  padding-top: 2 * $unit;
  padding-bottom: 2 * $unit;
}

.h-padding-half { padding: 0.5 * $unit; }
.h-padding-left-half { padding-left: 0.5 * $unit; }
.h-padding-right-half { padding-right: 0.5 * $unit; }
.h-padding-top-half { padding-top: 0.5 * $unit; }
.h-padding-bottom-half { padding-bottom: 0.5 * $unit; }
.h-padding-x-half {
  padding-left: 0.5 * $unit;
  padding-right: 0.5 * $unit;
}
.h-padding-y-half {
  padding-top: 0.5 * $unit;
  padding-bottom: 0.5 * $unit;
}

.h-padding-quarter { padding: 0.25 * $unit; }
.h-padding-left-quarter { padding-left: 0.25 * $unit; }
.h-padding-right-quarter { padding-right: 0.25 * $unit; }
.h-padding-top-quarter { padding-top: 0.25 * $unit; }
.h-padding-bottom-quarter { padding-bottom: 0.25 * $unit; }
.h-padding-x-quarter {
  padding-left: 0.25 * $unit;
  padding-right: 0.25 * $unit;
}
.h-padding-y-quarter {
  padding-top: 0.25 * $unit;
  padding-bottom: 0.25 * $unit;
}
