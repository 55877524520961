@import '../env';

@mixin m-hide-for-mq-1 {
  @media (max-width: $mq1MaxWidth) {
    display: none !important;
  }
}
@mixin m-hide-for-mq-2 {
  @media (min-width: $mq2MinWidth) and (max-width: $mq2MaxWidth) {
    display: none !important;
  }
}
@mixin m-hide-for-mq-3 {
  @media (min-width: $mq3MinWidth) and (max-width: $mq3MaxWidth) {
    display: none !important;
  }
}
@mixin m-hide-for-mq-4 {
  @media (min-width: $mq4MinWidth) and (max-width: $mq4MaxWidth) {
    display: none !important;
  }
}
@mixin m-hide-for-mq-5 {
  @media (min-width: $mq5MinWidth) and (max-width: $mq5MaxWidth) {
    display: none !important;
  }
}
@mixin m-hide-for-mq-6 {
  @media (min-width: $mq6MinWidth) {
    display: none !important;
  }
}

.h-hide-for-mq-1 {
  @include m-hide-for-mq-1();
}

.h-hide-for-mq-2 {
  @include m-hide-for-mq-2();
}

.h-hide-for-mq-3 {
  @include m-hide-for-mq-3();
}

.h-hide-for-mq-4 {
  @include m-hide-for-mq-4();
}

.h-hide-for-mq-5 {
  @include m-hide-for-mq-5();
}

.h-hide-for-mq-6 {
  @include m-hide-for-mq-6();
}

.h-hide-for-mq-lt-3 {
  @include m-hide-for-mq-1();
  @include m-hide-for-mq-2();
}

.h-hide-for-mq-lt-4 {
  @include m-hide-for-mq-1();
  @include m-hide-for-mq-2();
  @include m-hide-for-mq-3();
}

.h-hide-for-mq-gt-1 {
  @include m-hide-for-mq-2();
  @include m-hide-for-mq-3();
  @include m-hide-for-mq-4();
  @include m-hide-for-mq-5();
  @include m-hide-for-mq-6();
}

.h-hide-for-mq-gt-2 {
  @include m-hide-for-mq-3();
  @include m-hide-for-mq-4();
  @include m-hide-for-mq-5();
  @include m-hide-for-mq-6();
}

